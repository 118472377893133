import React, {FC} from 'react';
import {IconButton, MenuItem, Select, Slider, Stack, TableCell, TableRow, TextField} from "@mui/material";
import {ICalculatePlacesTypesByAreaAndDatesRule} from "./types";
import styles from "../CalculatePlacesTypes/calculatePlacesTypes.module.scss";
import CustomInputDate from "../UI/Inputs/CustomInputDate/CustomInputDate";
import Icon from "../UI/Icons/Icon";

interface Props {
  rule: ICalculatePlacesTypesByAreaAndDatesRule;
  changeRule: (rule: ICalculatePlacesTypesByAreaAndDatesRule) => void;
  onDelete: () => void;
}

const CalculatePlacesTypesByAreaAndDatesInputRow: FC<Props> = ({changeRule, rule, onDelete}) => {
  const typeItems = [
    {key: 'single', label: 'Одиночное',},
    {key: 'related', label: 'Родственное',},
    {key: 'family', label: 'Семейное(родовое)',},
  ]

  const onChangeValue = (value: string) => {
    if (['single', 'related', 'family'].includes(value)) {
      changeRule({...rule, value: value as 'single' | 'related' | 'family'})
    }
  }

  const onChangeDate = (variant: "start" | "end", value: string) => {
    if (value.length !== 10) {
      return
    }

    changeRule({...rule, dates: {...rule.dates, [variant]: value}})
  }

  const handleChangeArea = (value: number | number[]) => {
    if (typeof value === 'number') {
      return
    }

    changeRule({...rule, area: value})
  }

  return (
    <>
      <TableRow>
        <TableCell rowSpan={2}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rule.value}
            label="Формат"
            onChange={(e) => onChangeValue(e.target.value)}
          >
            {typeItems.map(({key, label}) => (
              <MenuItem key={key} value={key}>{label}</MenuItem>
            ))}
          </Select>
        </TableCell>

        <TableCell colSpan={2}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction={"row"}>
              <Slider
                marks
                className={styles.slider}
                value={rule.area}
                step={0.5}
                size="small"
                onChange={(_, value) => handleChangeArea(value)}
                valueLabelDisplay="auto"
                max={50}
              />

              <div style={{width: '9rem'}}>
                От {rule.area[0]} м² до {rule.area[1]} м²
              </div>
            </Stack>

            <IconButton onClick={onDelete}>
              <Icon icon={'delete'} color={'#ff0000'}/>
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <div>От</div>
            <TextField
              size={'small'}
              value={rule.dates.start}
              onChange={(e) => onChangeDate("start", e.target.value)}
              InputProps={{
                inputComponent: CustomInputDate as any,
                style: {width: "8rem"}
              }}
            />
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <div>До</div>
            <TextField
              size={'small'}
              value={rule.dates.end}
              onChange={(e) => onChangeDate("end", e.target.value)}

              InputProps={{
                inputComponent: CustomInputDate as any,
                style: {width: "8rem"}
              }}
            />
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CalculatePlacesTypesByAreaAndDatesInputRow;