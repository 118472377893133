import React, {FC, useState} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import {toast} from 'react-toastify';
import {LoadingButton} from '@mui/lab';
import Loader from '../UI/Loader/Loader';
import {useCustomMutation} from '../../api/useCustomMutation';
import {
  EXPORT_BURIAL_REGISTRATION_BOOK_URL,
  EXPORT_DESCRIPTION_URL,
  EXPORT_GEOJSON_ALL_URL,
  EXPORT_INVENTORY_PDF_URL,
  EXPORT_INVENTORY_URL,
  EXPORT_NEW_GEOJSON_ONE_URL, EXPORT_NEW_GEOJSON_ONE_WITHOUT_GRAVES_GEOMETRY_URL,
  EXPORT_NEW_RGIS_URL,
  EXPORT_OLD_GEOJSON_ONE_URL,
  EXPORT_OLD_RGIS_URL,
  EXPORT_TOMB_STRUCTURES_REGISTRATION_BOOK_URL,
  EXPORT_USER_REPORT_URL
} from "../../api/urls";
import styles from './exportDataFromDB.module.scss';
import SelectProject from "../SelectProject/SelectProject";
import {useRecoilState} from "recoil";
import {currentProjectAtom} from "../../store/projectStore";

interface Props {
  isLoading?: boolean,
  needShowSelect?: boolean
}

const ExportDataFromDB: FC<Props> = ({ isLoading, needShowSelect = true }) => {
  const mutation = useCustomMutation()
  const [links, setLinks] = useState<string[]>([])
  const [url, setUrl] = useState<string>()
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectAtom)

  const onProjectClick = (project: any) => {
    setCurrentProject(project)
  }

  const exportMenuItems = [
    { url: EXPORT_GEOJSON_ALL_URL, name: 'GEOJSON' },
    { url: EXPORT_OLD_GEOJSON_ONE_URL, name: 'РГИС_2023(.geojson) ' },
    { url: EXPORT_NEW_GEOJSON_ONE_URL, name: 'РГИС_2024(.geojson) ' },
    { url: EXPORT_NEW_GEOJSON_ONE_WITHOUT_GRAVES_GEOMETRY_URL, name: 'РГИС_2024.2(.geojson) ' },
    { url: EXPORT_OLD_RGIS_URL, name: 'РГИС_2023(.xlsx)' },
    { url: EXPORT_NEW_RGIS_URL, name: 'РГИС_2024(.xlsx)' },
    { url: EXPORT_INVENTORY_URL, name: 'Список захоронений' },
    { url: EXPORT_DESCRIPTION_URL, name: 'Инвентаризационная опись захоронений' },
    { url: EXPORT_BURIAL_REGISTRATION_BOOK_URL, name: 'Книга регистрации захоронений' },
    { url: EXPORT_TOMB_STRUCTURES_REGISTRATION_BOOK_URL, name: 'Книга регистрации надмогильных сооружений' },
    { url: EXPORT_INVENTORY_PDF_URL, name: 'Отчёт по инветаризации захоронений' },
    // { url: EXPORT_USER_REPORT_URL, name: 'Сформировать отчёт по шаблону' },
  ]

  const handleChange = (e: any) => {
    setUrl(e.target.value)
  }

  const onExport = async (form: any | undefined) => {
    try {
      if (!url || !currentProject) {
        return
      }

      let data

      if (url === EXPORT_USER_REPORT_URL) {
        if (!form.File[0]) {
          toast.error('Шаблон отчёта не выбран');
          return;
        }

        const formData = new FormData()

        formData.append('file', form.File[0])
        formData.append('schema', currentProject.schema)

        const { data: userReportData } = await mutation.mutateAsync({
          method: 'post',
          data: formData,
          url
        })

        data = userReportData
      } else {
        const { data: exportData } = await mutation.mutateAsync({
          method: 'get',
          url: url + `?schema=${currentProject.schema}`
        })

        data = exportData
      }

      toast.success(data.message)

      if (!data.urls) {
        return
      }

      setLinks(data.urls)

      setTimeout(() => {
        setLinks([])
      }, 120000)

    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onClear = () => {
    setLinks([])
  }

  if (isLoading) {
    return <Loader/>
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={`Отчёт по кладбищу ${currentProject ? currentProject.name : ''}`}/>

      <CardContent>
        {needShowSelect &&
            <div>
                <SelectProject onProjectClick={onProjectClick}/>

                <h2>{currentProject ? currentProject.name : 'Выберите проект'}</h2>
            </div>
        }

        <FormControl fullWidth className={styles.select__format}>
          <InputLabel id="demo-simple-select-label">Формат</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={url ?? ''}
            label="Формат"
            onChange={handleChange}
          >
            {exportMenuItems.map(({ url, name }) => (
              <MenuItem key={url} value={url}>{name}</MenuItem>
            ))}
          </Select>

          {/*{url === EXPORT_USER_REPORT_URL &&*/}
          {/*    <div className={styles.select__file}>*/}
          {/*        <TextField*/}
          {/*          {...register('File', { required: false })}*/}
          {/*          size={'small'}*/}
          {/*          type={'file'}*/}
          {/*          label={'Шаблон'}*/}
          {/*          InputLabelProps={inputLabelProps}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*}*/}
        </FormControl>

        <FormHelperText>
          После успешного экспорта ссылки и файлы на сервере живут 120 секунд
        </FormHelperText>

        {links.length > 0 &&
          links.map(link =>
            <Stack key={link} spacing={1} padding={1}>
              <a download href={link} target={'_blank'} rel="noreferrer">{link}</a>
            </Stack>
          )}

      </CardContent>

      <CardActions>
        <LoadingButton
          disabled={!url}
          variant={'contained'}
          loading={mutation.isLoading}
          onClick={onExport}
          // onClick={url === EXPORT_USER_REPORT_URL ? handleSubmit(onExport) : onExport}
        >
          Экспортировать
        </LoadingButton>

        <Button onClick={onClear} disabled={!links.length} variant={'contained'}>
          Очистить
        </Button>
      </CardActions>
    </Card>
  );
};

export default ExportDataFromDB;