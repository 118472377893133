import React, {useState} from 'react';
import {ls_getProjectInfo, ProjectInfo} from "../../helpers/localStorage";
import {useCustomMutation} from "../../api/useCustomMutation";
import {toast} from "react-toastify";
import styles from "../CalculatePlacesTypes/calculatePlacesTypes.module.scss";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import {algorithmsTitles} from "../../utils/utils";
import {Button, Card, CardActions, CardContent, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import SelectProject from "../SelectProject/SelectProject";
import {LoadingButton} from "@mui/lab";
import {ICalculatePlacesTypesByAreaAndDatesRule} from "./types";
import CalculatePlacesTypesByAreaAndDatesInputRow from "./CalculatePlacesTypesByAreaAndDatesInputRow";
import {
  CALCULATE_PLACES_TYPES_BY_AREA_AND_DATES_URL,
  WRITE_USER_HISTORY_URL
} from "../../api/urls";

const DEFAULT_RULES: ICalculatePlacesTypesByAreaAndDatesRule[] = [
  {area: [0, 3], dates: {start: undefined, end: undefined}, value: "single"},
  {area: [3, 5], dates: {start: undefined, end: undefined}, value: "related"},
  {area: [5, 10], dates: {start: undefined, end: undefined}, value: "family"}
]

const CalculatePlacesTypesByAreaAndDates = () => {
  const [project, setProject] = useState<ProjectInfo | undefined>(ls_getProjectInfo())
  const mutation = useCustomMutation()
  const [rules, setRules] = useState<ICalculatePlacesTypesByAreaAndDatesRule[]>(DEFAULT_RULES)

  const onChangeRule = (rule: ICalculatePlacesTypesByAreaAndDatesRule, index: number) => {
    setRules(prev => prev.map((r, i) => i === index ? rule : r));
  }

  const onDeleteRule = (index: number) => {
    setRules(prev =>
      prev.filter((_, i) => i !== index)
    );
  }

  const onProjectClick = (project: ProjectInfo) => {
    setProject(project)
  }

  const onAddRule = () => {
    setRules(prev => [...prev, {area: [0, 3], dates: {start: undefined, end: undefined}, value: "related"}])
  }

  const onInfoClick = () => {
    toast(
      <>
        Обновляем типы оград по выбранным площадям и минимальным датам могил
      </>
    )
  }

  const onSubmit = async () => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'calcPlacesTypesByAreaAndDates', }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'patch',
        data: {
          schema: project.schema,
          rules: rules.map(r=>({...r, area: {start: r.area[0], end: r.area[1]}})),
        },
        url: CALCULATE_PLACES_TYPES_BY_AREA_AND_DATES_URL
      })

      toast.success(data.message)
    }catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.CalculatePlacesTypesByAreaAndDates} onInfoClick={onInfoClick}/>
      <CardContent>
        <Stack spacing={2}>
          <h2>{project ? project.name : 'Выберите проект'}</h2>
          <SelectProject onProjectClick={onProjectClick}/>

          <Typography variant={'h5'}>Введите правила</Typography>

          <Table>
            <TableBody>
              {rules.map((r, i) => (
                <CalculatePlacesTypesByAreaAndDatesInputRow
                  key={i}
                  onDelete={() => onDeleteRule(i)}
                  rule={r}
                  changeRule={(rule) => onChangeRule(rule, i)}
                />
              ))}
            </TableBody>
          </Table>
        </Stack>
      </CardContent>

      <CardActions>
        <Button onClick={onAddRule}>
          + Добавить правило
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant={'contained'}
          onClick={onSubmit}
        >
          Запустить
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default CalculatePlacesTypesByAreaAndDates;