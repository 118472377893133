import React, {FC} from 'react';
import styles from './tabs.module.scss';
import {NavLink} from 'react-router-dom';
import PermissionsGate from '../../../helpers/permissions/PermissionsGate';

interface ITabsProps {
  links: { label: string, to: string, scopes: string[], linkOut?: boolean }[]
}

const Tabs: FC<ITabsProps> = ({links,}) => {
  return (
    <div className={styles.links_wrapper}>
      <div className={styles.links}>
        {links.map(link => (
          <PermissionsGate key={link.to} scopes={link.scopes}>
            {link.linkOut ?
              <a target={"_blank"} href={link.to}>{link.label}</a>
              :
              <NavLink
                className={({isActive}) => isActive ? styles.active : ''}
                to={link.to}
              >
                {link.label}
              </NavLink>
            }

          </PermissionsGate>
        ))}
      </div>
    </div>
  );
};

export default Tabs;