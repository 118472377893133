import React from 'react';
import cn from "classnames";
import styles from "./customButtons.module.scss";
import WhereAmIButton from "./WhereAmIButton";
import Ruler from "./ruller/Ruler";
import SelectFinger from "./SelectFinger";
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import {SCOPES} from "../../../helpers/permissions/permissionsMaps";
import SetRowNumber from "./SetRowNumberButton/SetRowNumber";
import EditGeometryModeButton from "./EditGeometryModeButton/EditGeometryModeButton";

const CustomButtonsContainer = () => {
  return (
    <div className={cn('leaflet-top', 'leaflet-left', styles.custom_buttons_container)}>
      <WhereAmIButton/>
      <Ruler/>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <SelectFinger/>
        <SetRowNumber/>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.editorUser, SCOPES.superUser, SCOPES.experiencedWorker]}>
        <EditGeometryModeButton/>
      </PermissionsGate>
    </div>
  );
};

export default CustomButtonsContainer;