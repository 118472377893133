import React from 'react';
import {Grid, Stack} from "@mui/material";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import {SCOPES} from "../../../../helpers/permissions/permissionsMaps";
import CalculatePlacesTypes from "../../../../components/CalculatePlacesTypes/CalculatePlacesTypes";
import MovePhotosFromKz from "../../../../components/MovePhotosFromKz/MovePhotosFromKz";
import FixDates from "../../../../components/fixDates/FixDates";
import DeletePlacesWithoutGeometryAndPhotos
  from "../../../../components/DeletePlacesWithoutGeometryAndPhotos/DeletePlacesWithoutGeometryAndPhotos";
import DeleteTemporaryObjects from "../../../../components/DeleteTemporaryObjects/DeleteTemporaryObjects";
import CopySchema from "../../../../components/copySchema/CopySchema";
import CheckSequencesInDb from "../../../../components/checks/CheckSequencesInDB";
import CalculateDimensions from "../../../../components/calculateDimensions/CalculateDimensions";
import CheckGravesCoordinates from "../../../../components/checks/CheckGravesCoordinates";
import DeleteEmptyGraves from "../../../../components/deleteEmptyGraves/DeleteEmptyGraves";
import MovePhotosToNewFolder from "../../../../components/MovePhotosToNewFolder/MovePhotosToNewFolder";
import FixFio from "../../../../components/fixFio/FixFio";
import RotateAllPhotos from "../../../../components/RotateAllPhotos";
import CalculatePlacesTypesByAreaAndDates
  from "../../../../components/CalculatePlacesTypesByAreaAndDates/CalculatePlacesTypesByAreaAndDates";

const OtherFeatures = () => {
  return (
    <Grid container spacing={3} columns={12}>
      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Grid xs={4} item>
          <CheckSequencesInDb/>
        </Grid>
      </PermissionsGate>

      <Grid xs={4} item>
        <CalculateDimensions/>
      </Grid>

      <Grid xs={4} item>
        <FixFio/>
      </Grid>

      <Grid xs={4} item>
        <CheckGravesCoordinates/>
      </Grid>

      <Grid xs={4} item>
        <DeleteEmptyGraves/>
      </Grid>

      <Grid xs={4} item>
        <DeletePlacesWithoutGeometryAndPhotos/>
      </Grid>

      <Grid xs={4} item>
        <FixDates/>
      </Grid>

      <Grid xs={4} item>
        <DeleteTemporaryObjects/>
      </Grid>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
        <Grid xs={4} item>
          <MovePhotosToNewFolder/>
        </Grid>

        <Grid xs={4} item>
          <MovePhotosFromKz/>
        </Grid>

        <Grid xs={4} item>
          <RotateAllPhotos entity={"graves"}/>
        </Grid>

        <Grid xs={4} item>
          <RotateAllPhotos entity={"places"}/>
        </Grid>
      </PermissionsGate>

      <Grid xs={6} item>
        <Stack spacing={3}>
          <CopySchema/>
          <CalculatePlacesTypesByAreaAndDates/>
        </Stack>
      </Grid>

      <Grid xs={6} item>
        <CalculatePlacesTypes/>
      </Grid>
    </Grid>
  );
};

export default OtherFeatures;