import React, {createRef, useMemo, useState} from 'react';
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../../store/mapStore";
import {useCustomMutation} from "../../../../api/useCustomMutation";
import * as turf from "@turf/turf";
import {IGrave} from "../../../../models/IGrave";
import {ls_getProjectInfo} from "../../../../helpers/localStorage";
import {
  UPDATE_BORDER_URL,
  UPDATE_GRAVE_URL,
  UPDATE_NOTE_OR_TASK_URL,
  UPDATE_PLACE_URL,
  UPDATE_PLANNED_PLACE_BY_ID_URL, UPDATE_SECTION_URL
} from "../../../../api/urls";
import {toast} from "react-toastify";
import styles from "../customButtons.module.scss";
import Icon from "../../../UI/Icons/Icon";

const UseMovePlaceButtons = () => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const mutation = useCustomMutation()
  const [points, setPoints] = useState<undefined | number[][]>()
  const [step, setStep] = useState<{ angle: number, distance: number }>({angle: 5, distance: 50})
  const [elRefs, setElRefs] = useState([])

  const onSelect = (geometry: any) => {
    setPoints(geometry.coordinates[0])
    setElRefs((elRefs) =>
      Array(geometry.coordinates[0].length)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    )
  }

  const onAddPoint = () => {
    if (!points) {
      return;
    }

    const twoLastPoints = turf.points([points[points.length - 2],points[points.length - 1]])

    const center = turf.center(twoLastPoints);

    const newPoints = [...points.slice(0,-1), center.geometry.coordinates, points[points.length - 1]];

    setPoints(newPoints);

    setElRefs((elRefs) =>
      Array(newPoints.length)
        .fill('')
        .map((_, i) => elRefs[i] || createRef()),
    )
  }

  const onDragEnd = (newPoint: any, index: number) => {
    let newGeometry = {
      type: 'Polygon',
      coordinates:
        [points!.map((point: any, i: number) => {
          return i === index ? [newPoint.lng, newPoint.lat] : point
        })]
    }

    if (index === 0) {
      newGeometry.coordinates[0][newGeometry.coordinates[0].length - 1] = [newPoint.lng, newPoint.lat]
    }

    setMapState((prev) => ({...prev, selectedObject: {...mapState.selectedObject, geometry: newGeometry}}))
  }

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        const index = Number(e.target.options.alt)
        // @ts-ignore
        const marker = elRefs[index].current
        if (marker != null) {
          // @ts-ignore
          onDragEnd(marker.getLatLng(), index)
        }
      },
    }),
    [points],
  )

  const onArrowClick = (direction: 'left' | 'right' | 'top' | 'bottom') => {
    let angle = 0;

    switch (direction) {
      case "bottom":
        angle = 180
        break
      case "top":
        break
      case "left":
        angle = 270
        break
      case "right":
        angle = 90
        break
    }

    const geometry = turf.transformTranslate(mapState.selectedObject.geometry, step.distance, angle, {units: 'centimeters'})

    const gravesWithNewGeometry: IGrave[] = []

    if (mapState.selectedObject.graves?.length) {
      mapState.selectedObject.graves.map((grave: any) => {
        const geometry = turf.transformTranslate(grave.geometry, step.distance, angle, {units: 'centimeters'})
        gravesWithNewGeometry.push({...grave, geometry})
      })
    }

    const newPlace = {
      ...mapState.selectedObject,
      geometry,
      graves: gravesWithNewGeometry.length ? gravesWithNewGeometry : null
    }

    setMapState((prev) => ({...prev, selectedObject: newPlace}))
  }

  const onRotate = (direction: 'left' | 'right') => {
    const placeGeometry = turf.transformRotate(mapState.selectedObject.geometry, direction === "right" ? step.angle : -step.angle)
    const center = turf.center(mapState.selectedObject.geometry)
    const gravesWithNewGeometry: IGrave[] = []

    if (mapState.selectedObject.graves?.length) {
      mapState.selectedObject.graves.map((grave: any) => {
        const geometry = turf.transformRotate(grave.geometry, direction === "right" ? step.angle : -step.angle, {pivot: center})
        gravesWithNewGeometry.push({...grave, geometry})
      })
    }

    const newPlace = {
      ...mapState.selectedObject,
      geometry: placeGeometry,
      graves: gravesWithNewGeometry.length ? gravesWithNewGeometry : null
    }

    setMapState((prev) => ({...prev, selectedObject: newPlace}))
  }

  const onSave = async () => {
    try {
      const schema = ls_getProjectInfo().schema

      if (mapState.preSelectedObject?.type === 'section') {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema,
            geometry: mapState.selectedObject.geometry
          },
          url: UPDATE_SECTION_URL + mapState.preSelectedObject.id
        })

        toast.success('Изменения сохранены, перезагрузите страницу, чтобы увидеть их')
        return
      }

      if (mapState.preSelectedObject?.type === 'border') {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema,
            geometry: mapState.selectedObject.geometry
          },
          url: UPDATE_BORDER_URL + mapState.preSelectedObject.id
        })

        toast.success('Изменения сохранены, перезагрузите страницу, чтобы увидеть их')
        return
      }

      if (mapState.selectedObject?.type === 'note' || mapState.selectedObject?.type === 'task') {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            noteType: mapState.selectedObject.type,
            geometry: mapState.selectedObject.geometry,
          },
          url: UPDATE_NOTE_OR_TASK_URL + mapState.selectedObject.id,
        })

        if (!mapState.needReFetch) {
          setMapState((prev) => ({
            ...prev,
            needReFetch: true,
          }))
        }

        toast.success('Изменения сохранены, перезагрузите страницу, чтобы увидеть их')

        return
      }

      const url = mapState.preSelectedObject?.type === 'common' || mapState.preSelectedObject?.type === 'placeWithoutSection' ? UPDATE_PLACE_URL : UPDATE_PLANNED_PLACE_BY_ID_URL

      const firstMeasurement = turf.length(
        turf.lineString([
          mapState.selectedObject.geometry.coordinates[0][0],
          mapState.selectedObject.geometry.coordinates[0][1]
        ]),
        {units: 'meters'}
      )
      const secondMeasurement = turf.length(
        turf.lineString([
          mapState.selectedObject.geometry.coordinates[0][1],
          mapState.selectedObject.geometry.coordinates[0][2]
        ]),
        {units: 'meters'}
      )

      const length = firstMeasurement > secondMeasurement ? firstMeasurement : secondMeasurement;
      const width = firstMeasurement < secondMeasurement ? firstMeasurement : secondMeasurement;

      await mutation.mutateAsync({
        method: 'patch',
        data: {
          schema,
          geometry: mapState.selectedObject.geometry,
          length,
          width
        },
        url: url + mapState.selectedObject.id,
      })

      if (mapState.selectedObject.graves?.length) {
        for (const grave of mapState.selectedObject.graves) {
          await mutation.mutateAsync({
            method: 'patch',
            data: {
              schema,
              geometry: grave.geometry
            },
            url: UPDATE_GRAVE_URL + grave.id
          })
        }
      }

      if (!mapState.needReFetch) {
        setMapState((prev) => ({
          ...prev,
          needReFetch: true,
        }))
      }

      toast.success('Изменения сохранены')

    } catch (e: any) {
      console.log(e)
      toast.error(
        <div>
          <p>{e.response?.data?.error}</p>
          <p>{e.response?.data?.message}</p>
        </div>
      )
    }
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if (!mapState.selectedObject || mapState.selectedObject.editDescription) {
      return
    }

    switch (e.code) {
      case 'KeyA':
        onArrowClick('left')
        break
      case 'KeyS':
        onArrowClick('bottom')
        break
      case 'KeyD':
        onArrowClick('right')
        break
      case 'KeyW':
        onArrowClick('top')
        break
      case 'Escape':
        setMapState((prev) => ({...prev, selectedObject: null, preSelectedObject: null}))
        break
      case 'KeyQ':
        onRotate('left')
        break
      case 'KeyE':
        onRotate('right')
        break
      case 'ArrowRight':
        onChangeStep('angle', 1)
        break
      case 'ArrowLeft':
        onChangeStep('angle', -1)
        break
      case 'ArrowUp':
        onChangeStep('distance', 1)
        break
      case 'ArrowDown':
        onChangeStep('distance', -1)
        break
      case 'ShiftLeft':
        onChangeStep('shift', -1)
        break
      case 'ShiftRight':
        onChangeStep('shift', -1)
        break
      default:
        break
    }
  }

  const onInfo = () => {
    toast((
      <div>
        <p>
          Чтобы передвигать объект используйте кнопки
        </p>

        <div className={styles.info_icons_wrapper}>
          <Icon icon={"arrowBack"}/>
          <Icon icon={"arrowBack"} className={styles.arrow_top}/>
          <Icon icon={"arrowBack"} className={styles.arrow_right}/>
          <Icon icon={"arrowBack"} className={styles.arrow_bottom}/>
        </div>

        либо клавиши WASD

        <p>
          Чтобы крутить ограду используйте кнопки
        </p>

        <div className={styles.info_icons_wrapper}>
          <Icon icon={"rotate"}/>
          <Icon icon={"rotate"} className={styles.mirroring}/>
        </div>

        либо клавиши QE

        <p>
          Чтобы сбросить изменения нажмите Escape, либо на крестик в окне справа внизу
        </p>

        <p>
          Чтобы изменить дистанцию передвижения, нажимайте клавиши ↑ ↓
        </p>

        <p>
          Чтобы изменить угол поворота, нажимайте клавиши ← →
        </p>

        <p>
          Чтобы быстро изменить угол поворота и дистанцию передвижения, нажимайте клавишу Shift
        </p>

        <p>
          Перетаскивайте маркеры, чтобы изменять крайние точки
        </p>

        <p>
          Чтобы сохранить изменения нажмите зелёную кнопку слева экрана
        </p>

        <Icon icon={"save"}/>
      </div>
    ), {autoClose: 30000})
  }

  const onChangeStep = (entity: 'angle' | 'distance' | 'shift', direction: -1 | 1) => {
    if (entity === 'angle' && step.angle <= 1 && direction === -1) {
      toast.error('Нельзя задать угол меньше 1')
      return
    }

    if (entity === 'distance' && step.distance <= 1 && direction === -1) {
      toast.error('Нельзя задать передвижение меньше 1')
      return
    }

    const newStep = step

    if (entity === 'shift') {
      if (step.angle !== 1 || step.distance !== 10) {
        newStep.angle = 1
        newStep.distance = 10
      }
    } else {
      newStep[entity] += direction
    }

    setStep(newStep)

    toast.success(
      <div>
        <p>Текующие значения:</p>
        <p>Передвижения: {step.distance}см</p>
        <p>Поворот: {step.angle}°</p>
      </div>
    )
  }

  return {onArrowClick, onRotate, onSave, onInfo, onKeyDown, onSelect,onAddPoint, points, mapState, eventHandlers, elRefs}
};

export default UseMovePlaceButtons;