import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {getProjects} from '../../api/project/projectApi';
import {useRecoilState} from 'recoil';
import {projectsState} from '../../store/projectStore';
import {Outlet} from 'react-router-dom';
import styles from './overview.module.scss';
import Loader from '../../components/UI/Loader/Loader';
import Tabs from '../../components/UI/Tabs/Tabs';
import {SCOPES} from '../../helpers/permissions/permissionsMaps';
import {
  DECODE_PAGE,
  GRAVES_PAGE,
  MAP_PAGE,
  PLACES_PAGE,
  PROJECTS_PAGE,
  USER_MAP_LAYERS_PAGE
} from '../../router/routeConsts';

const OverView = () => {
  const {isLoading, data} = useQuery(['current-project'], getProjects)
  const [, setProjects] = useRecoilState(projectsState)

  const links = [
    {label: 'Кладбища', to: PROJECTS_PAGE, scopes: [SCOPES.admin, SCOPES.user]},
    {label: 'Ограды', to: PLACES_PAGE, scopes: [SCOPES.admin, SCOPES.user]},
    {label: 'Могилы', to: GRAVES_PAGE, scopes: [SCOPES.admin, SCOPES.user]},
    {label: 'Карта', to: MAP_PAGE, scopes: [SCOPES.admin, SCOPES.user]},
    {
      label: 'ПОМОЩЬ',
      linkOut: true,
      to: 'https://rutube.ru/video/private/b9f95e5e028693a4a1bfaa9fc5af7d20/?p=222lgi6-R71eq2Q27FDc9g',
      scopes: [SCOPES.admin, SCOPES.user]
    },
    {label: 'Расшифровка фото', to: DECODE_PAGE + ':id', scopes: [SCOPES.admin, SCOPES.superAdmin, SCOPES.decoder]},
    {label: 'Слои карты', to: USER_MAP_LAYERS_PAGE, scopes: [SCOPES.superAdmin]},
  ]

  useEffect(() => {
    if (data) {
      setProjects({
        ...projectsState,
        projects: data.data
      })
    }
  }, [isLoading])

  if (isLoading || !data) {
    return <Loader/>
  }

  return (
    <div className={styles.wrapper}>
      <Tabs links={links}/>
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
};

export default OverView;