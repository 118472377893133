import React, {FC, useEffect, useMemo, useState} from 'react';
import {
  CircleMarker,
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Tooltip,
  useMap,
  useMapEvents
} from 'react-leaflet';
import MapLayersControl from '../MapLayers/MapLayersControl';
import {booleanWithin, center, flip, getCoord, point, polygon} from '@turf/turf';
import {useRecoilState} from 'recoil';
import {mapStateAtom} from '../../../../store/mapStore';
import EditControl from '../../../../components/EditControl/EditControl';
import * as uuid from 'uuid';
import {toast} from 'react-toastify';
import {ls_getProjectInfo} from '../../../../helpers/localStorage';
import {IGrave} from "../../../../models/IGrave";
import PermissionsGate from "../../../../helpers/permissions/PermissionsGate";
import {SCOPES} from "../../../../helpers/permissions/permissionsMaps";
import {showWrongPlacesRgisAtom} from "../../../../store/wrongPlacesRgisStore";
import {LeafletEventHandlerFnMap} from "leaflet";
import useDebounce from "../../../../helpers/useDebounce";

interface ProjectMapProps {
  borders: any[]
  sections: any[]
  placesWithPhotos: any[]
  placesWithoutPhotos: any[]
  placesWithoutSections: any[]
  gravesWithPhoto: any[]
  gravesWithoutPhoto: any[]
  gravesWithoutPlace: any[]
  placesWithoutPhotoOnCroc: any[]
  gravesWithoutPhotoOnCroc: any[]
  plannedPlaces: any[]
  showObjectsWithoutPhotoOnCroc: boolean
  sectionsCenters: any[]
  onAddSection: (value: string) => void
  notes: any[],
  tasks: any[],
  userMapLayers: any[]
}

const initialCenter: any = [55.763441, 37.592812];

const ProjectMap: FC<ProjectMapProps> = ({
                                           gravesWithoutPhoto,
                                           gravesWithPhoto,
                                           placesWithPhotos,
                                           placesWithoutPhotos,
                                           placesWithoutSections,
                                           sections,
                                           borders,
                                           gravesWithoutPhotoOnCroc,
                                           placesWithoutPhotoOnCroc,
                                           showObjectsWithoutPhotoOnCroc,
                                           sectionsCenters,
                                           onAddSection,
                                           plannedPlaces,
                                           tasks,
                                           notes,
                                           gravesWithoutPlace,
                                           userMapLayers
                                         }) => {
  const [mapState, setMapState] = useRecoilState(mapStateAtom)
  const [showWrongPlacesOnMap] = useRecoilState(showWrongPlacesRgisAtom)
  const [baseLayer, setBaseLayer] = useState<any>()
  const [sectionClick, setSectionClick] = useState<any>()
  const debouncedSectionClick = useDebounce(sectionClick, 300)
  const mapRef = useMap()

  useMapEvents({
    baselayerchange: event => {
      setBaseLayer(event.name)
    },
  })

  const taskAndNotesHandlers = useMemo(
    () => ({
      async click(e: any) {
        let fill = [] as any[]

        fill = tasks.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))

        if (!fill[0] || !fill[0].id) {
          fill = notes.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))
        }

        if (mapState.selectFinger.isOn) {
          setMapState((prev) => ({
            ...prev,
            selectFinger: {isOn: false, placesArray: [], sectionsArray: []}
          }))
        }

        setMapState((prev) => ({
          ...prev,
          selectedObject: {...fill[0]},
          preSelectedObject: null
        }))
      }
    }),
    [notes, tasks, mapState, setMapState]
  )

  const placeHandlers = useMemo(
    () => ({
      async click(e: any) {
        let type = 'common' as 'common' | 'planned' | 'placeWithoutSection'

        let fill = [] as any[]
        fill = placesWithPhotos.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))

        if (!fill[0] || !fill[0].id) {
          fill = placesWithoutPhotos.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))
        }

        if (!fill[0] || !fill[0].id) {
          fill = plannedPlaces.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))
          type = 'planned'
        }

        if (!fill[0] || !fill[0].id) {
          fill = placesWithoutSections.filter((p) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), p.geometry))
          type = 'placeWithoutSection'
        }

        let placeId = null as null | number

        if (fill[0] && fill[0].id !== mapState.preSelectedObject?.id) {
          placeId = fill[0].id
        }

        if (mapState.selectFinger.isOn) {
          if (type === 'planned') {
            toast.error('Нельзя переносить запланированныые ограды')
            return
          }

          const filteredPlace = mapState.selectFinger.placesArray.filter((p) => p.id === placeId).length

          let newPlacesArr = [] as any[]

          if (filteredPlace) {
            newPlacesArr = mapState.selectFinger.placesArray.filter((p) => p.id !== placeId)
          } else {
            newPlacesArr = [...mapState.selectFinger.placesArray, {
              id: placeId,
              placeNumber: fill[0].placeNumber,
              uuid: fill[0].uuid
            }]
          }

          setMapState(prev => ({
            ...prev,
            selectFinger: {
              ...mapState.selectFinger,
              placesArray: newPlacesArr
            }
          }))
        } else {
          setMapState(prev => ({
            ...prev,
            selectedObject: null,
            preSelectedObject: placeId ? {id: Number(placeId), type} : null,
          }))
        }
      },
    }),
    [placesWithoutPhotos, plannedPlaces, placesWithPhotos, placesWithoutSections, setMapState, mapState.preSelectedObject, mapState.selectFinger]
  )

  const onSectionClick = (e: any) => {
    const section = sections.filter((s) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), s.geometry))

    if (section.length) {
      if (!mapState.selectFinger.isOn) {
        onAddSection(section[0].sectionNumber)
      }
      // toast(`Номер сектора: ${section[0].sectionNumber}`);
    }

    if (mapState.selectFinger.isOn) {
      const filteredSection = mapState.selectFinger.sectionsArray.filter((s) => s.id === section[0].id).length

      let newSectionArr = [] as any[]

      if (filteredSection) {
        newSectionArr = mapState.selectFinger.sectionsArray.filter((s) => s.id !== section[0].id)
      } else {
        newSectionArr = [...mapState.selectFinger.sectionsArray, {
          id: section[0].id,
          sectionNumber: section[0].sectionNumber,
          uuid: section[0].uuid
        }]
      }

      setMapState(prev => ({
        ...prev,
        selectFinger: {
          ...mapState.selectFinger,
          sectionsArray: newSectionArr
        }
      }))
    }
  }

  const onSectionDblClick = (e: any) => {
    const section = sections.find((s) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), s.geometry))

    if (mapState.selectedObject?.uuid !== section.uuid) {
      setMapState(prev => ({
        ...prev,
        selectedObject: section,
        preSelectedObject: {id: section.id, type: 'section'}
      }))
    } else {
      setMapState(prev => ({...prev, selectedObject: null, preSelectedObject: null}))
    }
  }

  const sectionsHandlers = useMemo(
    () => ({
      click(e: any) {
        setSectionClick({e, action: 'click'})
      },
      dblclick(e: any) {
        setSectionClick({e, action: 'dblclick'})
      },
    } as LeafletEventHandlerFnMap), [])

  const bordersHandlers = useMemo(
    () => ({
      dblclick(e: any) {
        const border = borders.find((b) => booleanWithin(point([e.latlng.lng, e.latlng.lat]), b.geometry))

        if (mapState.selectedObject?.uuid !== border.uuid) {
          setMapState(prev => ({
            ...prev,
            selectedObject: border,
            preSelectedObject: {id: border.id, type: 'border'}
          }))
        } else {
          setMapState(prev => ({...prev, selectedObject: null}))
        }
      },
    } as LeafletEventHandlerFnMap), [borders, mapState.selectedObject, setMapState])

  const onEdited = (e: any) => {
    const layer = e.layer.editing.latlngs[0]

    const feature = polygon(layer)

    // @ts-ignore
    feature.geometry.coordinates[0] = feature.geometry.coordinates[0].map(({lat, lng}) => ([lng, lat]))
    feature.geometry.coordinates[0].push(feature.geometry.coordinates[0][0])

    const placeDto = {
      schema: ls_getProjectInfo().schema,
      uuid: uuid.v4(),
      geometry: feature.geometry,
    }

    setMapState((prev) => ({...prev, createdObject: placeDto}))
  }

  const getGraveCenter = (grave: IGrave): [number, number] => {
    return [grave.geometry.coordinates[1], grave.geometry.coordinates[0]]
  }

  const gravesEventHandlers = useMemo(
    () => ({
      click(e: any) {
        const grave = gravesWithoutPlace.find((g) => g.id === e.target.options.className)

        if (mapState.selectedObject?.uuid !== grave.uuid) {
          setMapState(prev => ({
            ...prev,
            selectedObject: grave,
            preSelectedObject: {id: grave.id, type: 'grave'}
          }))
        } else {
          setMapState(prev => ({...prev, selectedObject: null}))
        }
      },
    } as LeafletEventHandlerFnMap), [gravesWithoutPlace, mapState.selectedObject, setMapState])

  useEffect(() => {
    if (debouncedSectionClick) {
      if (debouncedSectionClick.action === 'click') {
        onSectionClick(debouncedSectionClick.e)
      } else {
        onSectionDblClick(debouncedSectionClick.e)
      }
    }
  }, [debouncedSectionClick])

  useEffect(() => {
    let newCenter

    if (!mapState.center?.length) {
      if (borders[0]) {
        newCenter = getCoord(flip(center(borders[0].geometry)))
      } else {
        newCenter = initialCenter
      }
    } else {
      newCenter = mapState.center
    }

    // @ts-ignore
    mapRef.setView([...newCenter], mapState.zoom)
    mapRef.setMaxZoom(27)
  }, [borders])

  useEffect(() => {
    const bottomLeafletBar = document.getElementsByClassName('leaflet-control-attribution')

    const russianFlag = document.createElement('svg')
    russianFlag.innerHTML = '<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="12" height="8"><path fill="#fff" d="M0 0h12v4H0Z"></path><path fill="#0047ff" d="M0 3h12v4H0Z"></path><path fill="#ff0000" d="M0 6h12v4H0Z"></path></svg>'

    bottomLeafletBar[0]?.children[0]?.children[0]?.replaceWith(russianFlag)
  }, [baseLayer])

  return (
    <LayersControl position="topright" collapsed={false}>

      <MapLayersControl/>

      <FeatureGroup>
        <EditControl
          position={'topleft'}
          draw={{
            polygon: {
              allowIntersection: false,
              showArea: true,
              shapeOptions: {
                color: '#ff0000'
              }
            },
            // rectangle: {
            //   metric: true,
            //   shapeOptions: {
            //     color: '#ff0000'
            //   }
            // },
            rectangle: false,
            circle: false,
            polyline: false,
            marker: false,
            circlemarker: false
          }}
          onCreated={onEdited}
          onEdited={onEdited}
        />
      </FeatureGroup>

      <LayersControl.Overlay name={'границы'} checked={true}>
        <FeatureGroup>
          {borders.map(border => (
            <Polygon
              key={border.uuid}
              positions={mapState.selectedObject?.uuid === border.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[border.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              eventHandlers={mapState.isOnRulers ? {} : bordersHandlers}
              stroke={true}
              pathOptions={{color: mapState.selectedObject?.uuid === border.uuid ? 'rgb(31,203,34)' : 'rgb(20,128,236)'}}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'секторы'} checked={true}>
        <FeatureGroup>
          {sections.map(section => (
            <Polygon
              key={section.uuid}
              positions={mapState.selectedObject?.uuid === section.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[section.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              stroke={true}
              pathOptions={{color: mapState.selectFinger.sectionsArray.some((s) => s.uuid === section.uuid) || mapState.selectedObject?.uuid === section.uuid ? 'rgb(31,203,34)' : 'rgb(20,128,236)'}}
              eventHandlers={mapState.isOnRulers ? {} : sectionsHandlers}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      {userMapLayers.map((layer) => (
        <LayersControl.Overlay name={layer.name} checked={true} key={layer}>
          <FeatureGroup>
            <Polygon
              positions={[[layer.geometry.coordinates[0].map((arr: any[])=>arr.map((a: any[])=>([a[1],a[0]]))) || []]]}
              stroke={true}
              pathOptions={{color: layer.color}}
            />
          </FeatureGroup>
        </LayersControl.Overlay>
      ))}

      <LayersControl.Overlay name={'Запланированые ограды'} checked={true}>
        <FeatureGroup>
          {plannedPlaces.map(place => (
            <Polygon
              key={place.uuid}
              eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
              interactive={true}
              positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              stroke={true}
              pathOptions={{
                ...place.pathOptions,
                color: mapState.selectedObject?.uuid === place.uuid ? '#ff9700' : place.pathOptions.color,
              }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'ограды без сектора'} checked={true}>
        <FeatureGroup>
          {placesWithoutSections.map(place => (
            <Polygon
              key={place.uuid}
              eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
              interactive={true}
              positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              stroke={true}
              pathOptions={{
                ...place.pathOptions,
                color: mapState.selectedObject?.uuid === place.uuid || mapState.selectFinger.placesArray.some((p) => p.uuid === place.uuid) ? '#1fcb22' : place.pathOptions.color,
              }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'ограды'} checked={true}>
        <FeatureGroup>
          {placesWithPhotos.map(place => (
            <Polygon
              key={place.uuid}
              eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
              interactive={true}
              positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
              stroke={true}
              pathOptions={{
                ...place.pathOptions,
                color: mapState.selectedObject?.uuid === place.uuid || mapState.selectFinger.placesArray.some((p) => p.uuid === place.uuid) ? '#1fcb22' : place.pathOptions.color
              }}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.worker, SCOPES.experiencedWorker]}>
        <LayersControl.Overlay name={'ограды без фото'} checked={true}>
          <FeatureGroup>
            {placesWithoutPhotos.map(place => (
              <Polygon
                key={place.uuid}
                eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
                interactive={true}
                positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                stroke={true}
                pathOptions={{
                  ...place.pathOptions,
                  color: mapState.selectedObject?.uuid === place.uuid || mapState.selectFinger.placesArray.some((p) => p.uuid === place.uuid) ? '#1fcb22' : place.pathOptions.color,
                }}
              />
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>
      </PermissionsGate>

      {mapState.preSelectedSectionNumber &&
        <FeatureGroup>
          {sections.map((s) => (
              <div key={s.sectionNumber}>
                {s.sectionNumber !== mapState.preSelectedSectionNumber ?
                  <></>
                  :
                  <>
                    <Polygon
                      positions={[[s.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                      stroke={true}
                      color={'rgb(255,116,0)'}
                    />
                  </>
                }
              </div>
            )
          )}
        </FeatureGroup>
      }

      <LayersControl.Overlay name={'Номера секторов'} checked={true}>
        <FeatureGroup>
          {sectionsCenters.map((s) => (
            <Marker
              key={s.sectionNumber}
              position={getGraveCenter(s.center)}
              opacity={0}
            >
              <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>{s.sectionNumber}</Tooltip>
            </Marker>
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <LayersControl.Overlay name={'могилы c фото'} checked={false}>
        <FeatureGroup>
          {gravesWithPhoto.length > 0 && gravesWithPhoto.map(grave => (
            <CircleMarker
              key={grave.uuid}
              center={getGraveCenter(grave)}
              radius={3.3}
              weight={.3}
              pathOptions={grave.pathOptions}
            />
          ))}
        </FeatureGroup>
      </LayersControl.Overlay>

      <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.worker, SCOPES.experiencedWorker]}>
        <LayersControl.Overlay name={'могилы без фото'} checked={false}>
          <FeatureGroup>
            {gravesWithoutPhoto.length > 0 && gravesWithoutPhoto.map(grave => (
              <CircleMarker
                key={grave.uuid}
                center={[grave.geometry.coordinates[1], grave.geometry.coordinates[0]]}
                radius={3.3}
                weight={.3}
                pathOptions={grave.pathOptions}
              />
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name={'могилы без ограды'} checked={false}>
          <FeatureGroup>
            {gravesWithoutPlace.length > 0 && gravesWithoutPlace.map(grave => (
              <CircleMarker
                key={grave.uuid}
                center={[grave.geometry.coordinates[1], grave.geometry.coordinates[0]]}
                radius={6.3}
                weight={.8}
                pathOptions={{...grave.pathOptions, className: grave.id}}
                eventHandlers={gravesEventHandlers}
              />
            ))}
          </FeatureGroup>
        </LayersControl.Overlay>

        <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin, SCOPES.worker, SCOPES.experiencedWorker]}>
          <LayersControl.Overlay name={'Задачи полевиков'}>
            <FeatureGroup>
              {tasks.map(task => (
                <Polygon
                  eventHandlers={mapState.isOnRulers ? {} : taskAndNotesHandlers}
                  key={task.id}
                  interactive={true}
                  positions={mapState.selectedObject?.id === task.id ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[task.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                  stroke={true}
                  pathOptions={{
                    ...task.pathOptions,
                  }}
                />
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay name={'Заметки полевиков'}>
            <FeatureGroup>
              {notes.map(note => (
                <Polygon
                  eventHandlers={mapState.isOnRulers ? {} : taskAndNotesHandlers}
                  key={note.id}
                  interactive={true}
                  positions={mapState.selectedObject?.id === note.id ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[note.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                  stroke={true}
                  pathOptions={{
                    ...note.pathOptions,
                  }}
                />

              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
        </PermissionsGate>

        {showWrongPlacesOnMap.showOnMap &&
          <>
            <LayersControl.Overlay name={'Не совпадающие с ргис ограды'} checked={true}>
              <FeatureGroup>
                {showWrongPlacesOnMap.places.length > 0 && showWrongPlacesOnMap.places.map(place => {
                  if (place.geometry) {
                    return (
                      <Polygon
                        key={place.uuid}
                        eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
                        interactive={true}
                        positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                        stroke={true}
                        pathOptions={{
                          color: mapState.selectedObject?.uuid === place.uuid ? '#1fcb22' : '#ff0000',
                        }}
                      />
                    )
                  }
                })}
              </FeatureGroup>
            </LayersControl.Overlay>
          </>
        }

        {showObjectsWithoutPhotoOnCroc &&
          <>
            <LayersControl.Overlay name={'ограды с невыгружнными фотографиями'} checked={true}>
              <FeatureGroup>
                {placesWithoutPhotoOnCroc.length > 0 && placesWithoutPhotoOnCroc.map(place => {
                  if (place.geometry) {
                    return (
                      <Polygon
                        key={place.uuid}
                        eventHandlers={mapState.isOnRulers ? {} : placeHandlers}
                        interactive={true}
                        positions={mapState.selectedObject?.uuid === place.uuid ? [[mapState.selectedObject.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]] : [[place.geometry.coordinates[0]?.map((arr: any) => ([arr[1], arr[0]])) || []]]}
                        stroke={true}
                        pathOptions={{
                          color: mapState.selectedObject?.uuid === place.uuid || mapState.selectFinger.placesArray.some((p) => p.uuid === place.uuid) ? '#1fcb22' : '#ff0000',
                        }}
                      />
                    )
                  }
                })}
              </FeatureGroup>
            </LayersControl.Overlay>

            <LayersControl.Overlay name={'могилы с невыгружнными фотографиями'} checked={true}>
              <FeatureGroup>
                {gravesWithoutPhotoOnCroc.length > 0 && gravesWithoutPhotoOnCroc.map(grave => {
                  if (grave.geometry) {
                    return (
                      <Marker
                        key={grave.uuid}
                        position={[grave.geometry.coordinates[1], grave.geometry.coordinates[0]]}
                      />
                    )
                  }
                })}
              </FeatureGroup>
            </LayersControl.Overlay>
          </>
        }
      </PermissionsGate>
    </LayersControl>
  );
};

export default ProjectMap;