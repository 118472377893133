export const BASE_URL = process.env.REACT_APP_BASE_URL

// auth urls
export const REFRESH_URL = '/auth/refresh'
export const SIGN_IN_URL = '/auth/login'
export const LOGOUT_URL = '/auth/logout'

// user urls
export const SIGN_UP_URL = '/user/register'
export const GET_ALL_USERS_URL = '/user/all'
export const GET_ALL_DELETED_USERS_URL = '/user/deleted'
export const GET_USER_URL = '/user/'
export const GET_USERS_ROLE_URL = '/user/role'
export const UPDATE_USER_URL = '/user/'
export const UPDATE_USER_ROLE_URL = '/user/role'
export const DELETE_USER_URL = '/user/'
export const ACTIVATE_USER_URL = '/user/activate/'
export const DEACTIVATE_USER_URL = '/user/deactivate/'
export const RESTORE_USER_URL = '/user/restore/'
export const CHECK_RESET_PASSWORD_LINK_URL = '/user/check-reset-link'
export const SEND_RESET_PASSWORD_LINK_URL = '/user/send-reset-password-link'
export const RESET_PASSWORD_URL = '/user/reset-password'

// userPermission urls
export const GET_USER_PERMISSIONS_URL = '/user-permission/'
export const CREATE_USER_PERMISSION_URL = '/user-permission/'
export const DELETE_USER_PERMISSION_URL = '/user-permission/'

// project urls
export const CREATE_PROJECT_URL = '/project'
export const GET_PROJECTS_URL = '/project'
export const GET_PROJECTS_WITH_PERMISSIONS_URL = '/project/withPermissions'
export const GET_PROJECTS_BY_MUNICIPALITY_URL = '/project/byMunicipality'
export const GET_ALL_DELETED_PROJECTS_URL = '/project/deleted'
export const GET_PROJECT_URL = '/project/'
export const GET_DATA_FOR_MAP_URL = '/project/desktop/map/'
export const GET_DATA_FOR_MAP_BY_SECTIONS_URL = '/project/desktop/map-by-sections/'
export const GET_DATA_FOR_DECODE_URL = '/project/desktop/decode'
export const UPDATE_PROJECT_URL = '/project/'
export const SOFT_DELETE_PROJECT_URL = '/project/remove/'
export const UNSOFT_DELETE_PROJECT_URL = '/project/delete/'
export const RESTORE_PROJECT_URL = '/project/restore/'
export const SYNC_PROJECT_BY_FILES_URL = '/project/sync-file'

// cemetery urls
export const CREATE_CEMETERIES_URL = '/cemetery'
export const GET_CEMETERIES_URL = '/cemetery'
export const GET_ALL_DELETED_CEMETERIES_URL = '/cemetery/deleted'
export const UPDATE_CEMETERIES_URL = '/cemetery/'
export const DELETE_CEMETERIES_URL = '/cemetery/'
export const RESTORE_CEMETERY_URL = '/cemetery/restore/'

// municipality urls
export const GET_MUNICIPALITIES_URL = '/municipality'
export const GET_ALL_DELETED_MUNICIPALITIES_URL = '/municipality/deleted'
export const CREATE_MUNICIPALITY_URL = '/municipality'
export const UPDATE_MUNICIPALITY_URL = '/municipality/'
export const DELETE_MUNICIPALITY_URL = '/municipality/'
export const RESTORE_MUNICIPALITY_URL = '/municipality/restore/'

// place urls
export const CREATE_PLACE_URL = '/place'
export const GET_PLACE_URL = '/place/'
export const GET_PLACES_URL = '/place'
export const GET_PLACES_SEARCH_RESULTS_URL = 'exchange/export/search'
export const GET_PLACE_STATUSES = '/place/place-statuses'
export const GET_PLACE_TYPES = '/place/place-types'
export const GET_PLACE_SECTIONS = '/place/place-sections'
export const UPDATE_PLACE_URL = '/place/'
export const DELETE_PLACE_URL = '/place/'
export const DELETE_PLACES_WITHOUT_GEOMETRY_AND_PHOTOS_URL = 'place/delete-places/'
export const DELETE_PLACES_WITH_TEMPORARY_RGIS_ID_URL = 'place/delete-temporary-places/'
export const DELETE_PLACE_PHOTO_URL = '/place/photo'
export const CALCULATE_PLACES_TYPES_URL = '/place/calc-places-types'
export const CALCULATE_PLACES_TYPES_BY_AREA_AND_DATES_URL = '/place/calc-places-types-by-area-and-dates'

// grave urls
export const CREATE_GRAVE_URL = '/grave'
export const GET_GRAVE_URL = '/grave/'
export const GET_GRAVES_COUNT_URL = '/grave/count'
export const GET_GRAVES_URL = '/grave'
export const GET_GRAVE_TYPES_URL = '/grave/grave-types'
export const GET_CASKET_TYPES_URL = '/grave/casket-types'
export const GET_BURIAL_TYPES_URL = '/grave/burial-types'
export const GET_SOURCES_URL = '/grave/grave-sources'
export const UPDATE_GRAVE_URL = '/grave/'
export const DELETE_GRAVE_URL = '/grave/'
export const DELETE_GRAVE_WITH_TEMPORARY_RGIS_ID_URL = '/grave/delete-temporary-graves'
export const DELETE_GRAVE_PHOTO_URL = '/grave/photo'
export const DELETE_ALL_EMPTY_GRAVES_URL = '/grave/empty-graves'

// sections
export const GET_SECTIONS_URL = '/section'
export const CREATE_SECTION_URL = '/section'
export const UPDATE_SECTION_URL = '/section/'
export const DELETE_SECTION_URL = '/section/'

//borders
export const CREATE_BORDER_URL = '/border'
export const GET_BORDERS_URL = '/border'
export const UPDATE_BORDER_URL = '/border/'
export const DELETE_BORDER_URL = '/border/'

// statistic
export const GET_PROJECT_WITHOUT_SCHEMA_DATA_URL = '/project/check-schema'
export const CREATE_USER_DECODE_INTERVALS_URL = '/statistic/decode'
export const GET_USER_DECODE_INTERVALS_URL = '/statistic/decode'
export const GET_USER_DECODE_INTERVAL_URL = '/statistic/decode/user'
export const UPDATE_USER_DECODE_INTERVALS_URL = '/statistic/decode/'
export const DELETE_USER_DECODE_INTERVALS_URL = '/statistic/decode/'
export const GET_USER_PHOTO_STATISTIC_URL = '/user/statistic/photo'
export const GET_USER_DECODE_HISTORY_URL = '/statistic/history/user'
export const GET_USERS_BY_SCHEMA_IN_DECODE_HISTORY_URL = '/statistic/history/all-users'
export const CREATE_HISTORY_URL = '/statistic/history'
export const GET_DECODE_HISTORY_BY_USER_URL = '/statistic/history'

// features urls
export const EXPORT_GEOJSON_ALL_URL = '/exchange/export/geojson/all'
export const EXPORT_OLD_GEOJSON_ONE_URL = '/exchange/export/geojson/one/old'
export const EXPORT_NEW_GEOJSON_ONE_URL = '/exchange/export/geojson/one/new'
export const EXPORT_NEW_GEOJSON_ONE_WITHOUT_GRAVES_GEOMETRY_URL = '/exchange/export/geojson/one/new-without-graves-geometry'
export const EXPORT_OLD_RGIS_URL = '/exchange/export/rgis/old'
export const EXPORT_NEW_RGIS_URL = '/exchange/export/rgis/new'
export const EXPORT_PLACE_URL = '/exchange/export/place'
export const EXPORT_GRAVE_URL = '/exchange/export/grave'
export const EXPORT_INVENTORY_URL = '/exchange/export/inventory'
export const EXPORT_INVENTORY_PDF_URL = '/exchange/export/pdf-inventory'
export const EXPORT_DESCRIPTION_URL = '/exchange/export/description'
export const EXPORT_BURIAL_REGISTRATION_BOOK_URL = '/exchange/export/burial-book'
export const EXPORT_TOMB_STRUCTURES_REGISTRATION_BOOK_URL = '/exchange/export/tomb-structures-book'
export const IMPORT_VECTORS_URL = '/exchange/import/vectors'
export const IMPORT_RGIS_URL = '/exchange/import/rgis'
export const IMPORT_FROM_RGIS_URL = '/exchange/import/from-rgis'
export const MERGE_RGIS_URL = '/exchange/internal/merge/rgis'
export const SAVE_RGIS_FILE_URL = '/exchange/internal/merge/save-rgis'
export const CHECK_RGIS_FILE_URL = '/exchange/internal/merge/rgis/check'
export const MERGE_RGIS_GRAVE_URL = '/exchange/internal/merge/grave'
export const GET_RGIS_PROGRESS_AND_RESULT_URL = BASE_URL + '/exchange/internal/merge/rgis-progress'
export const GET_BOOK_PROGRESS_AND_RESULT_URL = BASE_URL + '/exchange/internal/merge/book-progress'
export const MERGE_BOOK_URL = '/exchange/internal/merge/book'
export const MERGE_ASHES_URN_BOOK_URL = '/exchange/internal/merge/ashes-urn-book'
export const IMPORT_FROM_KZ_URL = '/exchange/import/kz-mp-postgres-auto'
export const MOVE_PHOTOS_FROM_KZ_URL = '/exchange/import/kz-move-photos'
export const MOVE_PLACE_AND_GRAVES_FROM_PROJECT_URL = '/exchange/import/from-project'
export const DISTRIBUTE_PHOTOS_ON_CROC_URL = '/exchange/import/distribute-photos'

//croc urls
export const DELETE_IMAGE_FROM_CROC_AND_DB_URL = '/croc/delete-image'
export const UPDATE_IMAGE_ON_CROC_URL = '/croc/update-image'
export const COPY_IMAGE_ON_CROC_AND_DB_URL = '/croc/copy-image'
export const MOVE_FOLDER_ON_CROC_URL = '/croc/move-folder'
export const CREATE_IMAGE_ON_CROC_AND_DB_URL = '/croc/create-image'
export const ROTATE_PHOTO_ON_CROC_URL = '/croc/rotate-one'
export const ROTATE_PHOTOS_ON_CROC_TO_ALBUM_URL = '/croc/rotate/album'
export const ROTATE_PHOTOS_ON_CROC_TO_BOOK_URL = '/croc/rotate/book'

//user actions history urls
export const WRITE_USER_HISTORY_URL = '/user-actions-history/pog'
export const GET_USER_ACTIONS_HISTORY_URL = '/user-actions-history'

//user-reports
export const GET_USER_TEMPLATES_URL = '/exchange/export/user-templates'
export const EXPORT_USER_REPORT_BY_SAVED_TEMPLATE_URL = '/exchange/export/saved-user-templates'
export const EXPORT_USER_REPORT_URL = '/exchange/export/user-report'
export const SAVE_USER_REPORT_URL = '/exchange/export/save-user-report'
export const DELETE_USER_REPORT_URL = '/exchange/export/user-template'

//planned places
export const CREATE_PLANNED_PLACE_URL = '/planned-place/'
export const GET_PLANNED_PLACE_BY_ID_URL = '/planned-place/'
export const UPDATE_PLANNED_PLACE_BY_ID_URL = '/planned-place/'
export const DELETE_PLANNED_PLACE_BY_ID_URL = '/planned-place/'
export const GET_PLANNED_PLACES_WITH_SEARCH_URL = '/planned-place/search'

//algorithms
export const SYNC_PHOTOS_URL = '/algorithms/sync-photos'
export const CHECK_PHOTOS_URL = '/algorithms/check-photos'
export const DELETE_PHOTOS_DUPLICATES_URL = '/algorithms/delete-photos-duplicates'
export const FIX_DATES_URL = '/algorithms/fix-dates'
export const COPY_SCHEMA_URL = '/algorithms/copy-schema'
export const SEARCH_STUBS_URL = '/algorithms/search-stubs'
export const FIND_OBJECTS_WITHOUT_GEOMETRY_URL = '/algorithms/find/noGeometry'
export const CALC_DIMENSIONS_URL = '/algorithms/calc-dimensions'
export const CHECK_GRAVES_COORDINATES_URL = '/algorithms/check-graves'
export const GET_DIFFERENCE_BETWEEN_RGIS_AND_OUR_DATA_URL = 'algorithms/findDuplicates/compare'
export const GET_GRAVES_DUPLICATES_URL = 'algorithms/findDuplicates/duplicates'
export const GET_AND_SYNC_GRAVES_DUPLICATES_URL = 'algorithms/findDuplicates/sync-duplicates'
export const CHECK_SEQUENCES_URL = '/algorithms/run/sequence'
export const FIX_FIO_URL = '/algorithms/fix-fio'
export const DELETE_NOT_EXISTING_PHOTOS_URL = '/algorithms/delete-all-not-existing-photos'
export const SET_ROW_NUMBER_BY_GEOMETRY_URL = '/algorithms/set-row-number'
export const SEARCH_PLACES_SECTIONS_URL = '/algorithms/search-place-section'

//note and task
export const CREATE_NOTE_OR_TASK_URL = '/notes-tasks'
export const UPDATE_NOTE_OR_TASK_URL = '/notes-tasks/'
export const DELETE_NOTE_OR_TASK_URL = '/notes-tasks/'

//replace
export const REPLACE_SECTION_BY_FILE_URL = '/exchange/replace/section'
export const REPLACE_BORDER_BY_FILE_URL = '/exchange/replace/border'
export const REPLACE_PLACES_BY_FILE_URL = '/exchange/replace/place'

//user map layers
export const CREATE_USER_MAP_LAYER_URL = '/userMapLayers'
export const GET_USER_MAP_LAYERS_URL = '/userMapLayers'
export const UPDATE_USER_MAP_LAYER_URL = '/userMapLayers/'
