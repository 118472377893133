import React, { useState } from 'react';
import styles from "./calculatePlacesTypes.module.scss";
import {
  Card,
  CardActions,
  CardContent,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import SelectProject from "../SelectProject/SelectProject";
import { LoadingButton } from "@mui/lab";
import { ls_getProjectInfo, ProjectInfo } from "../../helpers/localStorage";
import { useCustomMutation } from "../../api/useCustomMutation";
import { CALCULATE_PLACES_TYPES_URL, WRITE_USER_HISTORY_URL } from "../../api/urls";
import { toast } from "react-toastify";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";

interface IPlacesTypesIntervals {
  single: number[]
  related: number[]
  family: number[]
}

const CalculatePlacesTypes = () => {
  const [project, setProject] = useState<ProjectInfo | undefined>(ls_getProjectInfo())
  const mutation = useCustomMutation()

  const [value, setValue] = useState<IPlacesTypesIntervals>({
    single: [0, 3],
    related: [3, 5],
    family: [5, 10]
  });

  const handleChange = (event: Event, newValue: number | number[], type: 'single' | 'family' | 'related') => {
    setValue((prev) => ({ ...prev, [type]: newValue }));
  };

  const numberArrayToInterval = (arr: number[]) => {
    return {
      max: arr[0] > arr[1] ? arr[0] : arr[1],
      min: arr[0] < arr[1] ? arr[0] : arr[1]
    }
  }

  const onProjectClick = (project: ProjectInfo) => {
    setProject(project)
  }

  const checkIntervals = () => {
    const checkSet = new Set()

    for (let i = value.single[0]; i < value.single[1]; i += 0.5) {
      if (!checkSet.has(i)) {
        checkSet.add(i)
      } else {
        return false
      }
    }

    for (let i = value.family[0]; i < value.family[1]; i += 0.5) {
      if (!checkSet.has(i)) {
        checkSet.add(i)
      } else {
        return false
      }
    }

    for (let i = value.related[0]; i < value.related[1]; i += 0.5) {
      if (!checkSet.has(i)) {
        checkSet.add(i)
      } else {
        return false
      }
    }

    return true
  }

  const onClick = async () => {
    try {
      if (!project) {
        toast.error('Выберите проект')
        return
      }

      if (!checkIntervals()) {
        toast.error('Интервалы пересекаются')
        return
      }

      let changesDescription = {
        action: 'algorithm',
        objectId: project.id,
        data: [{ algorithmName: 'calcPlacesTypes', }]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const { data } = await mutation.mutateAsync({
        method: 'patch',
        data: {
          schema: project.schema,
          intervals: {
            single: numberArrayToInterval(value.single),
            family: numberArrayToInterval(value.family),
            related: numberArrayToInterval(value.related)
          }
        },
        url: CALCULATE_PLACES_TYPES_URL
      })

      toast.success(data.message)
    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Обновляем типы оград по выбранным площадям
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.CalculatePlacesTypes} onInfoClick={onInfoClick}/>

      <CardContent>
        <Stack spacing={2}>
          <h2>{project ? project.name : 'Выберите проект'}</h2>
          <SelectProject onProjectClick={onProjectClick}/>

          <Typography variant={'h5'}>Выберите площади</Typography>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  Одиночное
                </TableCell>

                <TableCell>
                  <Slider
                    className={styles.slider}
                    onChange={(event, value) => handleChange(event, value, 'single')}
                    value={value.single}
                    step={0.5}
                    valueLabelDisplay="auto"
                    max={10}
                    size="small"
                    marks
                  />
                </TableCell>

                <TableCell>
                  <div style={{ width: '9rem' }}>
                    От {value.single[0]} м² до {value.single[1]} м²
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Родственное
                </TableCell>

                <TableCell>
                  <Slider
                    marks
                    className={styles.slider}
                    value={value.related}
                    step={0.5}
                    size="small"
                    onChange={(event, value) => handleChange(event, value, 'related')}
                    valueLabelDisplay="auto"
                    max={20}
                  />
                </TableCell>

                <TableCell>
                  <div style={{ width: '9rem' }}>
                    От {value.related[0]} м² до {value.related[1]} м²
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Семейное(родовое)
                </TableCell>

                <TableCell>
                  <Slider
                    marks
                    className={styles.slider}
                    step={0.5}
                    value={value.family}
                    max={80}
                    size="small"
                    onChange={(event, value) => handleChange(event, value, 'family')}
                    valueLabelDisplay="auto"
                  />
                </TableCell>

                <TableCell>
                  <div style={{ width: '9rem' }}>
                    От {value.family[0]} м² до {value.family[1]} м²
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </CardContent>

      <CardActions>
        <LoadingButton
          loading={mutation.isLoading}
          variant={'contained'}
          onClick={onClick}
        >
          Запустить
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default CalculatePlacesTypes;